@import "./style/mixins";
@import "./style/function";

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  scroll-behavior: smooth;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
  margin: 0;
  font-family: "Hyundai", "NotoSansArabic", "Arial", "sans-serif";
  font-size: rem(16);
  letter-spacing: 0.02em;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

button {
  border: none;
}

button, textarea, input, form, select {
  font-family: "Hyundai", "NotoSansArabic", "Arial", "sans-serif";
}

input {
  border-radius: 0;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 rem(16);

  @include min-media("t") {
    padding: 0 rem(36);
  }

  @include min-media("d") {
    box-sizing: content-box;
  }

  &--block {
    @include min-media('d') {
      padding: 0 rem(9);
    }
  }
}
